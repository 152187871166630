export default {
  ["마"]: "00",
  ["마감"]: "00",
  ["삭제 요청"]: "10",
  ["승인 요청"]: "20",
  ["승인 반려"]: "30",
  ["반려"]: "30",
  ["반"]: "30",
  ["승인"]: "40",
  ["승인 확인"]: "40",
  ["수"]: "50",
  ["수정"]: "50",
  ["수정 승인"]: "50",
  ["수정 승인 요청"]: "50",
  ["마감 요청"]: "00",
  ["생"]: null,
  ["생성"]: null,
  ["취"]: "90",
  ["취소"]: "90",
  ["취소 요청"]: "90",
  ["삭"]: "99",
  ["삭제"]: "99",
};
